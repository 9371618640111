
.has-white-color {
  color: #FFF
}

.has-dark-green-color {
  color: $dark-green;
}

.has-dark-green-background-color {
  background-color: $dark-green !important;
}

.has-yellow-color {
  color: $yellow;
}

.has-yellow-background-color {
  background-color: $yellow;
}


.has-gray-color {
  color: $gray
}

.has-gray-border {
  border: 1px solid #EEE;
}

.has-gray-background-color {
  background-color: $gray;
}

.has-white-background-color{
  background-color: #ffffff;
}
.has-dark-gray-color {
  color: $text-color;
}

.has-dark-gray-background-color {
  color: $text-color;
}

.has-green-color {
  color: $green-link;
}

.has-green-background-color {
  background-color: $green-link;
}

.has-orange-color {
  color: $orange;
}

.has-orange-background-color {
  background-color: $orange;
}

.has-ligth-green-color {
  color: $light-green
}

.has-ligth-green-background-color {
  background-color: $light-green;
}
