$font-family: 'Lato', sans-serif;
$title-font-family:'Museo Slab W01_700';

$blue: #3e53a6;
$light-blue: #5089a6;
$breadcrumb-grey: #535454;
$gray: #66676d;
$text-color: #555555;
$light-gray: #dadada;
$separator-grey: #ececec;
$red: #ba5555;
$menu-link-color: #54657e;
$link-green: #80973a;
$link-green-hover: #3f3f3f;
$link-color-default: #2271b1;
$export-green: #7dc646;
$chart-grey-title: #626262;
$tooltip-text-color: #868686;

$blue: #3e53a6;
$green: #84a43a;
$dark-green: #375e2d;

$table-theme-orange-bg: #f48e27;
$table-orange-bg: #e79f38;
$table-grey-bg: #e8e7e5;
$table-plus-green: #82C341;

$health-color: #5baf95;
$light-green: #31ba1a;
$indicator-border-color: #dfe2e5;
$background-selected-country: #fefaf2;
$charts-options-height: 66px;
$charts-options-font-size: 1.02rem;

@mixin gray-raleway-font {
  font-size: $charts-options-font-size;
  color: $gray;
  font-weight: 500;
  font-family: "Raleway";
}


$base-unit: 10px;
$base-unit-md: 20px;
/*MENUS*/
$dashboard-menu-height: 185px;
$dashboard-menu-height-home: 80px;
$yellow-header: 20px;
$title-header: 80px;
$extend: 100%;

/*TASAI*/
$header-border-width: 300px;
$country-filter-height: 60px;
$header-border-width: 250px;
$header-border-width-800: 120px;
$screen-width: 1350px;

/*COLORS*/
$yellow: #EFCB16;
$orange: #f38e28;
$green: #4d843f;
$green-link: #84a43a;
$text-color: #555555;
$text-color-dark: #3F3F3F;
$menu-text-color: #555555;
$odd_grey: #f6f6f6;
$wide-background-color: #dde0e3;

$white: #ffffff;
$menu-font-size: 16px;
$logos-color: brightness(0) invert(0.4);

// Mobile breakpoints
$breakpoint-tablet-landscape: 1024px;
$breakpoint-tablet-portrait: 768px;
$breakpoint-phone: 480px;

// Mobile Mixins
@mixin tablet {
  @media (max-width: $breakpoint-tablet-landscape) { @content; }
}
@mixin tablet-portrait {
  @media (max-width: $breakpoint-tablet-portrait) { @content; }
}
@mixin phone {
  @media (max-width: $breakpoint-phone) { @content; }
}

@mixin menu-icon {
  font-size: $menu-font-size - 1;
  font-weight: 600;
  outline: none;
  background-repeat: no-repeat;
  padding-left: calc(2.50em + 0.75rem);
  background-position: left calc(0.100em + 0.1875rem) center;
  filter: brightness(0) invert(1);
  background-size: 45px 45px;
  cursor: pointer;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin align-content {
  display: flex;
  flex-direction: column;
  justify-content: center
}

@mixin font-filter {
  font-size: 14px;
  color: white;
  font-weight: 600;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin border-radius($radius) {
  border-radius: $radius;
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
}

// Buttons
@mixin button($radius, $bg-color, $text-color){
  @include border-radius($radius);
  background-color: $bg-color;
  font-size: 16px !important;
  color: $text-color;
  letter-spacing: 1px;
}
@mixin button-hover{
  background-color: $text-color;
  color: #ffffff;
}
// Event icons
@mixin event-icon(){
  background-position: center;
  background-size: 110% 110%;
  display: block;
  float: left;
  height: $base-unit * 3;
  width: $base-unit * 3;
  &:before{
    content: none;
  }
}

@mixin display-flex($justify-content) {
  display: flex !important;
  justify-content: $justify-content;
  align-items: center;
}

@mixin borders-15 {
  background: $wide-background-color;
  padding-right: 15px;
  padding-left: 15px;;
  .ui.three.column.grid {
    background: white;
    height: 100%;
  }
}

@mixin list-unstyled{
  list-style: none;
}

@mixin country-crop-icon(){
  background-position: left center;
  background-repeat: no-repeat;
  background-size: $base-unit * 4;
  padding-left: $base-unit * 5;
}
