@import 'src/scss/vars';

.container.header {
  .background {
    border-bottom: 1px solid #dadada;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: rgb(218, 218, 218);
    height: $title-header;
  }

  &:not(.home) {
    // height: $dashboard-menu-height + $yellow-header + $title-header;
  }

  &.home {
    height: $dashboard-menu-height-home + $yellow-header + $title-header /  2.8;
    margin: 0;
  }

  .top_header {
    height: $yellow-header;
    background: $yellow;
  }

  .branding {
    &.menu {
      margin: 0;
      padding: 0;
      height: $title-header;

      .item {
        padding: 0;
      }

      .logo-container {
        @media (max-width: 1024px) {
          width: $header-border-width - $header-border-width-800;
          flex: 0 0 $header-border-width - $header-border-width-800;
        }
        @media (min-width: 1025px) {
          width: $header-border-width;
          flex: 0 0 $header-border-width;
        }
        padding-left: 30px;

        img {
          height: 90px;
          border: 0;

        }
      }
    }
  }

  .align-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .title-container {
    align-items: center;
    width: 100%;

    .title {
      text-align: center;
      color: $text-color;
      @media (max-width: 1024px) {
        font-size: 18pt;
      }
      @media (min-width: 1025px) {
        font-size: 31px;
      }
      font-weight: 500;
    }

    .regular-menu {
      .pages {
        display: flex;
        align-self: flex-end;
        width: auto;
        justify-content: center;
        padding-top: 25px;

        .item {
          a {
            color: $menu-text-color;

            &:hover {
              font-weight: bold;
              color: $yellow;
            }
          }

          @media (max-width: 1024px) {
            font-size: 10px;
          }
          @media (min-width: 1100px) and (max-width: 1200px) {
            font-size: 14px;
          }
          @media (min-width: 1201px) {
            font-size: 16px;
          }
          box-sizing: border-box;
          margin: 0;
          justify-content: center;
          min-width: 2%;
          align-self: flex-end;
          border: 1px solid transparent;
          padding: 0 2% 25px;
        }

        .item.selected {
          font-weight: bold;
          color: $yellow;

          a {
            color: $yellow;
          }
        }

        .item.has-child-items {
          padding-bottom: 25px;
        }

        .item.selected.has-child-items {
          margin-bottom: -1px;
          border-bottom-right-radius: 3px;
          border-bottom-left-radius: 3px;
          padding-bottom: 28px;

          :hover {
            cursor: pointer;
          }
        }
      }

    }

  }

  .lang-container {
    align-items: end;
    @media (max-width: 1024px) {
      width: $header-border-width - $header-border-width-800;
      flex: 0 0 $header-border-width -$header-border-width-800;
    }
    @media (min-width: 1025px) {
      width: $header-border-width;
      flex: 0 0 $header-border-width;
    }
    margin-right: 20px;

    .lang {
      cursor: pointer;
      display: block;

      > a {
        background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 24.2.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' focusable='false' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 192 512' style='enable-background:new 0 0 192 512;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23555555;%7D%0A%3C/style%3E%3Cpath class='st0' d='M0,384.7V127.3c0-17.8,21.5-26.7,34.1-14.1l128.7,128.7c7.8,7.8,7.8,20.5,0,28.3L34.1,398.8 C21.5,411.4,0,402.5,0,384.7z'/%3E%3C/svg%3E%0A");
        background-position: $base-unit-md 1px;
        background-repeat: no-repeat;
        background-size: $extend - 3 $extend - 3;
        color: $text-color;
        cursor: pointer;
        display: block;
        font-size: 13px;
        font-weight: 800;
        opacity: 1;
        padding-right: $base-unit-md + 10;

        &:hover {
          opacity: 0.6;
        }
      }
    }
  }

  .dashboard-menu {
    // height: $dashboard-menu-height;
    background-position: 50% 65%;
    background-repeat: no-repeat;
    background-size: 100%;
    display: flex;

    &.home {
      height: $dashboard-menu-height-home;

      ._country-overview {
        a {
          background-image: url("/static/images/dashboard.svg") !important;
        }

      }
    }

    /*&.dashboard {
      -webkit-transform: scaleX(-1);
      * {
        -webkit-transform: scaleX(-1);
      }
    }*/
    .menu {
      width: 100%;
      flex: 0 0 100%;
      height: 80px;
      display: flex;
      justify-content: center;
      background-color: rgba(0, 0, 0, 0.4);

      position: relative;
      z-index: 10;

      &.not-selected {
        background-color: transparent;
      }

      padding: 0;

      .item {
        width: 200px;
        height: 80px;
        font-size: $menu-font-size;
        cursor: pointer;
        display: flex;
        align-self: flex-end;
        justify-content: center;
        align-items: center;

        &:hover:not(.fitted) {
          background-color: rgba(239, 203, 22, 0.7);
        }

        &.selected, &.active {
          background-color: rgba(239, 203, 22, 0.7);
        }


        &.fitted {
          width: 40px;
        }

        a {
          @include menu-icon;
        }

        /*ABOUT*/
        &._our-story {
          a {
            background-image: url("/static/images/story.svg") !important;
          }
        }

        &._our-methods,
        &._methods {
          a {
            background-image: url("/static/images/methods.svg") !important;
          }
        }

        &._indicators {
          a {
            background-image: url("/static/images/partners.svg") !important;
          }
        }

        &._partners {
          a {
            background-image: url("/static/images/partners.svg") !important;
          }
        }

        /*PEOPLE*/
        &._team {
          a {
            background-image: url("/static/images/team.svg") !important;
          }
        }

        &._researchers {
          a {
            background-image: url("/static/images/researchers.svg") !important;
          }
        }

        &._board {
          a {
            background-image: url("/static/images/board.svg") !important;
          }
        }
        /* Media */
        &._tasai-press {
          a {
            background-image: url("/static/images/press.svg") !important;
          }
        }
        &._tasai-blog {
          a {
            background-image: url("/static/images/updates.svg") !important;
          }
        }

        /* Dashboard */
        &._dashboard {
          a {
            background-image: url("/static/images/dashboard.svg") !important;
          }
        }

        &._country-reports {
          a {
            background-image: url("/static/images/country_reports.svg") !important;
          }
        }

        &._other-publications {
          a {
            background-image: url("/static/images/other_publications.svg") !important;
          }
        }

        &._seed-info-portals,
        &._seeds-info-portals {
          a {
            background-image: url("/static/images/country_info.svg") !important;
          }
        }

        &._country-overview,
        &._dashboard-home {
          a {
            background-image: url("/static/images/dashboard.svg");
          }
          &.submenu {
            a {
              width: 180px;
              background-image: url("/static/images/country_dashboard.svg") !important;
            }
          }
        }

        &._cross-country-dashboard {
          width: 210px;
          flex: 0 0 210px;

          a {
            background-image: url("/static/images/cross_country_dashboard.svg") !important;;
          }
        }

        &._past-events {
          a {
            background-image: url("/static/images/past-events.svg") !important;;
          }
        }

        &._upcoming-events,
        &._no-upcoming-events{
          a {
            background-image: url("/static/images/upcoming-events.svg") !important;;
          }
        }

        &._data-summary {
          a {
            background-image: url("/static/images/data_summary_country_comparisons.svg") !important;;
          }
        }


      }
    }
  }
}

html:lang(fr) {
  .container.header {
    .dashboard-menu {
      .menu {
        .item {
          &:not(.fitted) {
            width: 220px;
          }
        }
      }
    }
  }
}
