@import 'src/scss/vars';

.wp-react-lib.tabbed.posts.container {
  margin: 0;
  min-height: 500px;
  padding: 0 20px;
  font-weight: 600;

  .ui.container.entry-content {
    margin: 0;
    padding: 20px 0;
  }

  .item {
    padding: 0 5px;
    flex: auto;

    &.active + .item, &:hover + .item {
      .label {
        &:before {
          border: 0;
        }
      }
    }

    &:first-child > div {
      &:before {
        border: 0;
      }
    }

    .label {
      &:before {
        content: "";
        position: absolute;
        left: 0;
        bottom: 25%;
        right: 0;
        width: 100%;
        height: 50%;
        border-left: 3px solid $yellow;
      }
    }

    &:hover {
      .label {
        background-color: $yellow;
        border-radius: .28571429rem;

        &:before {
          border: 0;
        }
      }
    }

    &.active {
      .label {
        background-color: $yellow;
        border-radius: .28571429rem;

        &:before {
          border: 0;
        }

      }
    }

    .label {
      div {
        height: 34px;
        width: 90%;
        display: flex;
        align-items: center;
        /*border-right: 3px solid $yellow;*/
      }

      &._service-to-smallholder-farmer,&._service-to-smallholder-farmers {
        background-image: url("/static/images/service-to-smallholder-farmer.svg") !important;
      }

      &._institutional-support,&._institutional-support-2 {
        background-image: url("/static/images/insitutional-support.svg") !important;
      }

      &._seeds-policy-regulation,&._seed-policy-and-regulations {
        background-image: url("/static/images/seeds-policy-regulation.svg") !important;
      }

      &._industry-competitiveness,&._industry-competitiveness-2 {
        background-image: url("/static/images/industry-competitiveness.svg") !important;
      }

      &._research-and-development,&._research-and-development-2 {
        background-image: url("/static/images/research-and-development.svg") !important;
      }

      &._country-overview {
        background-image: url("/static/images/country-overview.svg") !important;
      }

      background-repeat: no-repeat;
      background-color: transparent;
      padding-left: calc(4em + 0.75rem);
      max-width: 204px;
      min-width: 150px;
      width: $extend;
      height: 75px;
      margin: 0;
      font-size: 14px;
      color: $breadcrumb-grey;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.cross-country-tabs{
  .wp-react-lib.tabbed.posts.container{
    .item{
      flex: auto;
    }
    .label{
        width: auto;
    }
  }
  .chart.container{
    .ui.grid{
      margin-left: 0;
      margin-right: 0;
    }
  }
}
