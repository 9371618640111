.wp-block-wp-react-lib-components-newsletter {
  width: 100%;
  padding: 10px;
}

.wp-react-lib.newsLetter {

  text-align: center;
  max-width: 500px;
  height: 60px;
  margin: 1rem auto;

  .ui.message {
    margin: 0px;

    p {

      padding: 0px;
      margin: 0px;
      line-height: 5px;
    }
  }

  .wp-react-lib.newsLetter.form {
    padding: 0px;
    height: 42px;
    display: flex;
    justify-content: left;
    margin-bottom: 3px;

    .input {
      flex-grow: 1;
      height: 40px;
    }

    .ui.button {
      height: 40px;
      line-height: 70%;
      margin-left: 10px;
    }

  }
}
