@import '../vars';
@import 'colors';
@import 'fonts';
@import 'content';


* {
  line-height: normal;

}


h1.type {
  font-size: 1rem;
  padding: 0;
  margin: 0px;
  color: $light-blue;

  font-family: Roboto, sans-serif;

}


.wp-block-columns.compacted {
  padding: 2rem;
}


@import "header";


@import 'illicit';
@import 'tabbed';
@import 'myths';
@import 'responsive';
@import 'floating';
@import 'featured';
@import 'inline-list';
@import 'charts';
@import 'carousel';
@import 'pagemodules';
@import "footer";
@import "newsLetter";
@import "showCase";
@import "download"