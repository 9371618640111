@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import 'vars';

html {
  height: 100%;
  font-size: 18px !important;
}

body {
  height: 100%;
  padding: 0;
  margin: 0;
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000;
  background-color: transparent !important;
  font-size: 18px !important;
}


iframe {
  margin: 0px;
  padding: 0px;

}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a:focus {
  outline-style: none;
  box-shadow: none;
  border-color: transparent;
}
a{
  img{
    cursor: pointer;
    opacity: 1;
    &:hover{
      opacity: 0.85;
    }
  }
}
.ui {
  font-family: $font-family;
}

.ui.container.narrow {
  max-width: 1080px;
}
.ui.container.entry-content{
  width: 1366px !important;

}
.ui.container.fluid {
  .desktop {
    max-width: 1366px !important;

    &.home {
      .full-width-cover{
        &.intro{
          margin-top: -($base-unit * 3);
        }
      }
      .entry-content {
        position: relative;
        z-index: 1;
      }
    }

  }
}


.ui.primary.button {
  background-color: $light-blue !important;

}

.ui.secondary.button {
  background-color: $green !important;

}

@import "theme/index";
