@import 'src/scss/vars';

.container.wp-react-lib.footer {
  background-color: $yellow;
  line-height: 16px !important;
  .wp-block-columns.footer-table {
    > div:nth-child(1) {
      border-right: 1px solid $text-color-dark;
      padding-left: 10%;
    }
    margin: 0;
  }

  p {
    color: $text-color-dark;
  }

  a {
    color: $text-color-dark;

    &:hover {
      text-decoration: underline;
    }
  }

  .header {
    height: 20px;
    background-image: url("/static/images/footerBG.png");
    background-position: 50% 65%;
    background-repeat: no-repeat;
    background-size: 100%;
  }

  .wp-block-columns {
    &.follow_us {
      margin: 0;

      width: 50%;

      .wp-block-column {
        margin: 0;
      }

      img {
        filter: $logos-color;
      }
    }

    &.logos {
      img {
        filter: $logos-color;
      }
    }
  }
  .dashboard-logo{
    visibility: hidden;
  }
}

// Dashboard footer - hide logos dashboard class
.dashboard-wrapper{
	.container.wp-react-lib.footer{
		.dashboard-logo{
      visibility: visible;
		}
	}
}
